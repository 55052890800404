.ColourPicker-hue-base {
    width: 250px;
    height: 250px;
    position: relative;
    margin: 16px;
}

.ColourPicker-hue-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.ColourPicker-hue-overlay-black {
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0)) !important;
    touch-action: none;
}

.ColourPicker-hue-overlay-white {
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important;
}

.ColourPicker-hue-slider {
    position: relative;
    width: 250px;
    height: 8px;
    margin: 16px;
    background: linear-gradient(to right,
        rgb(255, 0, 0) 0%,
        rgb(255, 255, 0) 17%,
        rgb(0, 255, 0) 33%,
        rgb(0, 255, 255) 50%,
        rgb(0, 0, 255) 67%,
        rgb(255, 0, 255) 83%,
        rgb(255, 0, 0) 100%) !important;
}

.ColourPicker-swatch {
    width: 48px;
    height: 48px;
}

.ColourPicker-thumb {
    pointer-events: none;
    touch-action: none;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50% !important;
    margin: -8px;
}

.ColourPicker-thumb-hue {
    top: 4px;
}
