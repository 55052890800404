.MapStyleView-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.MapStyleView-section-header {
    display: block;
    padding-top: 12px;
}

.MapStyleView-section {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
}

.MapStyleView-swatch {
    box-sizing: border-box;
    display: inline-block;
    min-width: 250px;
    flex-basis: 25%;
    overflow: hidden;
}

